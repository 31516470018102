import React from "react";

export default ({ color }) => (
  <svg width="199" height="231" viewBox="0 0 199 231" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M86.35 223.987L95.3906 228.918C97.0344 230.562 97.8562 230.562 99.5 230.562C101.144 230.562 101.966 230.562 103.609 229.74L112.65 224.809C161.963 196.043 198.125 175.496 198.125 108.103V41.5309C198.125 38.2434 195.659 34.9559 192.372 34.134L101.966 1.25898C100.322 0.437109 97.8563 0.437109 96.2125 1.25898L5.80625 34.134C3.34062 34.9559 0.875 38.2434 0.875 41.5309V107.281C0.875 175.496 37.0375 196.043 86.35 223.987Z" fill="black" />
    <path d="M98.9068 155.029C121.161 152.576 137.212 132.546 134.759 110.292C132.305 88.0374 112.275 71.9861 90.0209 74.4398C67.7667 76.8936 51.7153 96.9234 54.1691 119.178C56.6229 141.432 76.6527 157.483 98.9068 155.029Z" fill="white" />
    <path d="M128.232 133.46C146.44 131.452 159.573 115.065 157.566 96.8566C155.558 78.6486 139.17 65.5156 120.962 67.5233C102.754 69.5309 89.621 85.9189 91.6287 104.127C93.6363 122.335 110.024 135.468 128.232 133.46Z" fill="#FFB000" />
  </svg>


);

